import React from 'react';
import { Slide, DialogTitle, Dialog, DialogContent, DialogActions, List, ListItem, ListItemText, Grid, Button, CssBaseline, Typography, Container, withStyles } from '@material-ui/core';
import { firebase } from './../Firebase';
import Snackbar from './../Components/Snackbar';
import Loader from './../Components/Loader';
import jwt_decode from 'jwt-decode';
import NotFound from './404';

const styles = (theme) => ({
	appbar: theme.mixins.toolbar,
	logo: {
		textAlign: 'center',
		width: '50%',
	},
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	loginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	heading: {
		fontWeight: 'bold',
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(6),
		fontSize: theme.spacing(6),
	},
	subHeader: {
		fontWeight: '800',
		fontSize: theme.spacing(3),
	},
	container: {
		marginTop: theme.spacing(5),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(10) },
		marginBottom: theme.spacing(5),
	},
	container_al: {
		marginTop: theme.spacing(12),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(15) },
		marginBottom: theme.spacing(6),
		minHeight: (window.innerHeight * 43.7) / 100,
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

class Nomination extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userEmail: '',
			userId: '',
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
			isLoading: true,
			name: '',
			email: '',
			phoneNumber: 0,
			address: '',
			rollno: 0,
			role: '',
			candidates: [],
			candidatesId: [],
			proposers: [],
			seconders: [],
			campaigners: [],
			proposersId: [],
			secondersId: [],
			teamDialogOpen: false,
			isAdmin: false,
			index: -1,
			type: '',
			confirmDialog: false,
			candidateToDelete: '',
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	closeTeamDialog = () => {
		this.setState({ teamDialogOpen: !this.state.teamDialogOpen });
	};

	deleteTeamMembers = () => {
		const adminComponent = this;

		if (this.state.type === 'proposers') {
			firebase
				.firestore()
				.collection('users')
				.doc(this.state.candidateToDelete)
				.collection('nominees')
				.doc(this.state.proposersId[this.state.index])
				.delete()
				.then(() => {
					adminComponent.renderSnackbar('Nominee successfully deleted!', 'success');
				})
				.catch((error) => {
					adminComponent.renderSnackbar('Error in deleting nominee, please try again!', 'error');
					console.error('Error removing document: ', error);
				});
		} else {
			firebase
				.firestore()
				.collection('users')
				.doc(this.state.candidateToDelete)
				.collection('nominees')
				.doc(this.state.secondersId[this.state.index])
				.delete()
				.then(() => {
					adminComponent.renderSnackbar('Nominee successfully deleted!', 'success');
				})
				.catch((error) => {
					console.error('Error removing document: ', error);
					adminComponent.renderSnackbar('Error in deleting nominee, please try again!', 'error');
				});
		}

		adminComponent.setState({ confirmDialog: false });
	};

	confirmDialogClose = (index, type) => {
		this.setState({ confirmDialog: !this.state.confirmDialog, index: index, type: type });
	};

	componentDidMount() {
		const profileComponent = this;

		if (localStorage.getItem('token') === null || localStorage.getItem('token') === '') {
		} else {
			var decoded = jwt_decode(localStorage.getItem('token'));

			firebase
				.firestore()
				.collection('users')
				.where('email', '==', decoded.email)
				.onSnapshot((querySnapshot) => {
					const profileId = [];

					querySnapshot.forEach(function (doc) {
						profileId.push(doc.data());
					});

					profileComponent.setState({
						isAdmin: profileId[0].isAdmin,
					});
				});
		}

		firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				profileComponent.setState({ userEmail: user.email, isLogin: true }, function () {
					firebase
						.firestore()
						.collection('users')
						.where('email', '==', profileComponent.state.userEmail)
						.onSnapshot((querySnapshot) => {
							const profileId = [];
							const proposers = [];
							const seconders = [];

							querySnapshot.forEach(function (doc) {
								profileId.push(doc.id);
							});

							firebase
								.firestore()
								.collection('users')
								.doc(profileId[0])
								.collection('nominees')
								.orderBy('name')
								.onSnapshot((querySnapshot) => {
									querySnapshot.forEach(function (doc) {
										if (doc.data().role === 'Proposer') {
											proposers.push(doc.data());
										} else if (doc.data().role === 'Seconder') {
											seconders.push(doc.data());
										}
									});

									profileComponent.setState({
										isLoading: false,
										userId: profileId[0],
										proposers: proposers,
										seconders: seconders,
									});
								});
						});
						
						const specificTimestamp = firebase.firestore.Timestamp.fromDate(new Date('2024-09-20T00:00:00Z'));


					firebase
						.firestore()
						.collection('users')
						//.where('role', '==', 'Campaigner')
						.where('timestamp', '>=', specificTimestamp)
						.orderBy('timestamp', 'desc' )
						// .orderBy('post', 'asc')
						// .orderBy('name')
						.limit(143)
						
						.onSnapshot((querySnapshot) => {
							const candidates = [];
							const candidatesId = [];

							querySnapshot.forEach(function (doc) {
								if (doc.data().post !== 'ADMIN') {
									candidates.push(doc.data());
									candidatesId.push(doc.id);
								}
							});

							profileComponent.setState({
								candidates: candidates,
								candidatesId: candidatesId,
							});
						});
				});
			} else {
				profileComponent.setState({ isLoading: false, isLogin: false });
			}
		});
	}

	viewCandidateDetails = (candidateId) => {
		const profileComponent = this;

		firebase
			.firestore()
			.collection('users')
			.doc(this.state.candidatesId[candidateId])
			.collection('nominees')
			.onSnapshot((querySnapshot) => {
				const proposers = [];
				const seconders = [];
				const proposersId = [];
				const secondersId = [];

				querySnapshot.forEach(function (doc) {
					if (doc.data().role === 'Proposer') {
						proposers.push(doc.data());
						proposersId.push(doc.id);
					} else if (doc.data().role === 'Seconder') {
						seconders.push(doc.data());
						secondersId.push(doc.id);
					}
				});

				profileComponent.setState({
					proposers: proposers,
					seconders: seconders,
					proposersId: proposersId,
					secondersId: secondersId,
					candidateToDelete: profileComponent.state.candidatesId[candidateId],
				});

				this.closeTeamDialog();
			});
	};

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	render() {
		const { classes } = this.props;

		return (
			<>
				<CssBaseline />
				<div className={classes.appbar}></div>

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				{this.state.isLoading ? (
					<Loader />
				) : (
					<>
						{this.state.isLogin && this.state.isAdmin ? (
							<Container maxWidth='md' className={classes.container}>
								<Typography variant='h4' align='center' gutterBottom className={classes.heading}>
									Admin Panel
								</Typography>

								<Grid container spacing={5}>
									<Grid item xs={12}>
										<List component='nav' aria-label='main mailbox folders'>
											{this.state.candidates.map((data, index) => (
												<ListItem button key={index}>
													<ListItemText primary={data.name} secondary={data.email} />
													<ListItemText primary={data.post} secondary={data.mobileNumber} />
													<ListItemText primary={data.roll} secondary={data.address} />
													<Button variant='contained' color='primary' onClick={this.viewCandidateDetails.bind(this, index)}>
														View
													</Button>
												</ListItem>
											))}
										</List>
									</Grid>
								</Grid>

								<Dialog maxWidth='lg' fullWidth={true} open={this.state.teamDialogOpen} TransitionComponent={Transition} keepMounted>
									<DialogTitle>{'Team Details'}</DialogTitle>
									<DialogContent>
										<Grid container spacing={5}>
											<Grid item xs={12} sm={6}>
												<Typography variant='h5' align='center' className={classes.subHeader}>
													Proposers
												</Typography>
												<List component='nav' aria-label='main mailbox folders'>
													{this.state.proposers.map((data, index) => (
														<ListItem button key={index} onClick={() => this.confirmDialogClose(index, 'proposers')}>
															<ListItemText primary={data.name} secondary={data.email} />
															<ListItemText primary={data.rollno} />
														</ListItem>
													))}
												</List>
												{this.state.proposers.length === 0 ? (
													<Typography variant='subtitle1' align='center'>
														You don't have any Proposers
													</Typography>
												) : null}
											</Grid>
											<Grid item xs={12} sm={6}>
												<Typography variant='h5' align='center' className={classes.subHeader}>
													Seconder
												</Typography>
												<List component='nav' aria-label='main mailbox folders'>
													{this.state.seconders.map((data, index) => (
														<ListItem button key={index} onClick={() => this.confirmDialogClose(index, 'seconders')}>
															<ListItemText primary={data.name} secondary={data.email} />
															<ListItemText primary={data.rollno} />
														</ListItem>
													))}
												</List>
												{this.state.seconders.length === 0 ? (
													<Typography variant='subtitle1' align='center'>
														You don't have any Seconders
													</Typography>
												) : null}
											</Grid>
										</Grid>
									</DialogContent>
									<DialogActions>
										<Button variant='contained' color='primary' onClick={this.closeTeamDialog}>
											Close
										</Button>
									</DialogActions>
								</Dialog>

								<Dialog open={this.state.confirmDialog} onClose={this.confirmDialogClose}>
									<DialogTitle id='alert-dialog-title'>Are you sure you want to delete?</DialogTitle>
									<DialogContent>
										<Button fullWidth variant='contained' color='primary' onClick={() => this.deleteTeamMembers()}>
											Delete
										</Button>
									</DialogContent>
								</Dialog>
							</Container>
						) : (
							<NotFound />
						)}
					</>
				)}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Nomination);
