import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStylesFacebook = makeStyles((theme) => ({
	root: {
		position: 'relative',
		minHeight: (window.innerHeight * 2) / 3,
	},
	bottom: {
		color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
		left: '47.8%',
	},
	top: {
		color: '#1a90ff',
		animationDuration: '550ms',
		position: 'absolute',
		left: '47.8%',
		marginTop: theme.spacing(6),
	},
	circle: {
		strokeLinecap: 'round',
	},
}));

function FacebookCircularProgress(props) {
	const classes = useStylesFacebook();

	return (
		<div className={classes.root}>
			<CircularProgress
				variant='indeterminate'
				disableShrink
				className={classes.top}
				classes={{
					circle: classes.circle,
				}}
				size={40}
				thickness={4}
				{...props}
			/>
		</div>
	);
}

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
});

export default function CustomizedProgressBars() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<FacebookCircularProgress />
		</div>
	);
}
