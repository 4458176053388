import React from 'react';
import { CssBaseline, CircularProgress, Typography, Button, TextField, Container, withStyles } from '@material-ui/core';
import { firebase } from './../Firebase';
import Snackbar from './../Components/Snackbar';
import { green } from '@material-ui/core/colors';

import jwt_decode from 'jwt-decode';
// import {Link} from 'react-router-dom'; 

const styles = (theme) => ({
	appbar: theme.mixins.toolbar,
	logo: {
		textAlign: 'center',
		width: '50%',
	},
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	loginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	container: {
		marginTop: theme.spacing(22),
		marginBottom: theme.spacing(7),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(15), minHeight: (window.innerHeight * 43.7) / 100 },
		minHeight: (window.innerHeight * 53) / 100,
	},
	wrapper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		position: 'relative',
	},
	mainHeader: {
		fontWeight: 'bold',
		fontSize: theme.spacing(6),
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
});

class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
			disabled: false,
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	componentDidMount() {
		if (localStorage.getItem('token') === null || localStorage.getItem('token') === '') {
		} else {
			var decoded = jwt_decode(localStorage.getItem('token'));
			var dateNow = new Date();

			if (decoded.exp < Math.floor(dateNow.getTime() / 1000)) {
				localStorage.removeItem('token');
				window.open('/login', '_self');
			} else {
				window.open('/', '_self');
			}
		}
	}
    handleSubmit=(e) =>{
        e.preventDefault();
		const forgotPass=this;
        firebase
        .auth()
        .sendPasswordResetEmail(this.state.email)
  .then(() => {
    // Password reset email sent!
    // ..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });
        forgotPass.renderSnackbar('Reset link has been sent to your account');
    }

	login = (e) => {
		e.preventDefault();

		const loginComponent = this;

		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (this.state.email !== '' && this.state.password !== '') {
			if (this.state.email.endsWith('@iitk.ac.in') && re.test(String(this.state.email).toLowerCase())) {
				loginComponent.setState({
					disabled: !loginComponent.state.disabled,
				});

				firebase
					.auth()
					.signInWithEmailAndPassword(this.state.email, this.state.password)
					.then((u) => {
						if (!u.user.emailVerified) {
							u.user
								.sendEmailVerification()
								.then(function () {
									firebase
										.auth()
										.signOut()
										.then(function () {
											loginComponent.renderSnackbar('Email not verified, check your inbox to verify your email!', 'info');
											loginComponent.setState({
												disabled: false,
											});
											return false;
										})
										.catch(function (error) {
											console.log(error);
											loginComponent.setState({
												disabled: false,
											});
											loginComponent.renderSnackbar(error.message, 'error');
										});
								})
								.catch(function (error) {
									console.log(error);
									loginComponent.renderSnackbar(error.message, 'error');
									loginComponent.setState({
										disabled: false,
									});
								});
						} else {
							return u.user.getIdToken();
						}
					})
					.then((token) => {
						if (token) {
							localStorage.setItem('token', token);
							loginComponent.renderSnackbar('LoggedIn Successfully!', 'success');
							window.open('/', '_self');
						}
					})
					.catch((err) => {
						console.log(err);
						loginComponent.renderSnackbar(err.message, 'error');
						loginComponent.setState({
							disabled: false,
						});
					});
			} else {
				loginComponent.renderSnackbar('Use your IITK Email Address!', 'error');
			}
		} else {
			loginComponent.renderSnackbar('Please fill all the fields!', 'error');
		}
	};

	render() {
		const { classes } = this.props;
        
		
		return (
			<>
				<CssBaseline />

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				<div className={classes.appbar}></div>

				<Container maxWidth='xl' className={classes.container}>
					<Typography variant='h2' align='center' className={classes.mainHeader} gutterBottom>
				Enter Your Email Id
					</Typography>
					<Container maxWidth='sm'>
						<TextField
							className={classes.inputField}
							name='email'
							value={this.state.email.trim()}
							label='Email-ID'
							onChange={this.onChange}
							placeholder='Enter your IITK email address'
							variant='outlined'
                            required
						/>
						
						<div className={classes.wrapper}>
							<Button onClick={this.handleSubmit} variant='contained' color='primary' fullWidth disabled={this.state.disabled}>
								Reset Password 
							</Button>
							{this.state.disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
						</div>
					</Container>
				</Container>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(ForgotPassword);
