import React from 'react';
import { List, ListItem, ListItemText, Grid, CssBaseline, Typography, Container, withStyles } from '@material-ui/core';
import { firebase } from './../Firebase';
import Snackbar from './../Components/Snackbar';
import Loader from './../Components/Loader';
import NotFound from './404';

const styles = (theme) => ({
	appbar: theme.mixins.toolbar,
	logo: {
		textAlign: 'center',
		width: '50%',
	},
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	loginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	heading: {
		fontWeight: 'bold',
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(6),
		fontSize: theme.spacing(6),
	},
	subHeader: {
		fontWeight: '800',
		fontSize: theme.spacing(3),
	},
	container: {
		marginTop: theme.spacing(5),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(15) },
		marginBottom: theme.spacing(6),
		minHeight: (window.innerHeight * 43.7) / 100,
	},
	container_al: {
		marginTop: theme.spacing(12),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(15) },
		marginBottom: theme.spacing(6),
		minHeight: (window.innerHeight * 43.7) / 100,
	},
});

class Nomination extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userEmail: '',
			userId: '',
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
			isLoading: true,
			name: '',
			email: '',
			phoneNumber: 0,
			address: '',
			rollno: 0,
			role: '',
			secondersId: [],
			proposersId: [],
			proposers: [],
			seconders: [],
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	componentDidMount() {
		const myTeamComponent = this;

		firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				myTeamComponent.setState({ userEmail: user.email, isLogin: true }, function () {
					firebase
						.firestore()
						.collection('users')
						.where('email', '==', myTeamComponent.state.userEmail)
						.onSnapshot((querySnapshot) => {
							const profileId = [];
							const proposers = [];
							const seconders = [];

							querySnapshot.forEach(function (doc) {
								profileId.push(doc.id);
							});

							firebase
								.firestore()
								.collection('users')
								.doc(profileId[0])
								.collection('nominees')
								.onSnapshot((querySnapshot) => {
									querySnapshot.forEach(function (doc) {
										if (doc.data().role === 'Proposer') {
											proposers.push(doc.data());
										} else if (doc.data().role === 'Seconder') {
											seconders.push(doc.data());
										}
									});

									myTeamComponent.setState({
										isLoading: false,
										userId: profileId[0],
										proposers: proposers,
										seconders: seconders,
									});
								});
						});
				});
			} else {
				myTeamComponent.setState({ isLoading: false, isLogin: false });
			}
		});
	}

	render() {
		const { classes } = this.props;

		return (
			<>
				<CssBaseline />
				<div className={classes.appbar}></div>

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				{this.state.isLoading ? (
					<Loader />
				) : (
					<>
						{this.state.isLogin ? (
							<Container maxWidth='md' className={classes.container}>
								<Typography variant='h4' align='center' gutterBottom className={classes.heading}>
									My Team
								</Typography>

								<Grid container spacing={5}>
									<Grid item xs={12} sm={6}>
										<Typography variant='h5' align='center' className={classes.subHeader}>
											Proposers
										</Typography>
										<List component='nav' aria-label='main mailbox folders'>
											{this.state.proposers.map((data, index) => (
												<ListItem button key={index}>
													<ListItemText primary={data.name} secondary={data.email} />
													<ListItemText primary={data.rollno} />
												</ListItem>
											))}
										</List>
										{this.state.proposers.length === 0 ? (
											<Typography variant='subtitle1' align='center'>
												You don't have any Proposers
											</Typography>
										) : null}
									</Grid>
									<Grid item xs={12} sm={6}>
										<Typography variant='h5' align='center' className={classes.subHeader}>
											Seconder
										</Typography>
										<List component='nav' aria-label='main mailbox folders'>
											{this.state.seconders.map((data, index) => (
												<ListItem button key={index}>
													<ListItemText primary={data.name} secondary={data.email} />
													<ListItemText primary={data.rollno} />
												</ListItem>
											))}
										</List>
										{this.state.seconders.length === 0 ? (
											<Typography variant='subtitle1' align='center'>
												You don't have any Seconders
											</Typography>
										) : null}
									</Grid>
								</Grid>
							</Container>
						) : (
							<NotFound />
						)}
					</>
				)}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Nomination);
