import React from 'react';
import { FormControl, CircularProgress, InputLabel, Select, CssBaseline, Typography, Button, TextField, Container, withStyles } from '@material-ui/core';
import { firebase } from './../Firebase';
import { green } from '@material-ui/core/colors';
import Snackbar from './../Components/Snackbar';
import jwt_decode from 'jwt-decode';

const styles = (theme) => ({
	appbar: theme.mixins.toolbar,
	logo: {
		textAlign: 'center',
		width: '50%',
	},
	inputField: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	loginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	buttonProgress: {
		color: green[500],
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	wrapper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		position: 'relative',
	},
	container: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(5),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(10) },
		minHeight: (window.innerHeight * 43.7) / 100,
	},
	mainHeader: {
		fontWeight: 'bold',
		fontSize: theme.spacing(6),
	},
});

class Signup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			password: '',
			confirmPassword: '',
			role: 'Candidate',
			post: '',
			roll: '',
			mobileNumber: '',
			disabled: false,
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	signup = (e) => {
		e.preventDefault();

		const signupComponent = this;

		const { name, email, password, confirmPassword, role, post, mobileNumber } = signupComponent.state;
		email.toLowerCase();

		let date1 = new Date();
		let date2 = new Date('September 24, 2024 00:00:00');
		let date3 = new Date('September 25, 2024 15:00:00');

		if (date1 < date2 && email !== 'akankshab22@iitk.ac.in' && email!=='abhinavg21@iitk.ac.in') {
			signupComponent.renderSnackbar('Nominations filling starts at 24 September, 12 AM!', 'error');
			return;
		}

		if (date1 > date3 && email !== 'akankshab22@iitk.ac.in' && email!=='abhinavg21@iitk.ac.in') {
			signupComponent.renderSnackbar('Nominations filling has ended!', 'error');
			return;
		}

		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (name !== '' && email !== '' && password !== '' && confirmPassword !== '' && role !== '') {
			if (password === confirmPassword) {
				if ((role === 'Candidate' && post !== '') || role === 'Proposer' || role === 'Seconder' || role === 'Campaigner') {
					if (email.includes('@iitk.ac.in') && re.test(String(email).toLowerCase())) {
						if (mobileNumber.length === 10) {
							signupComponent.setState({
								disabled: !signupComponent.state.disabled,
							});

							firebase
								.auth()
								.createUserWithEmailAndPassword(email, password)
								.then((userCredential) => {
									var user = userCredential.user;

									user.updateProfile({
										displayName: name,
									})
										.then(() => {
											console.log('Username updated!');

											user.sendEmailVerification()
												.then(function () {
													console.log('Email Sent!');

													signupComponent.addDataToFirestore();
												})
												.catch(function (error) {
													alert(error);
													signupComponent.setState({
														disabled: !signupComponent.state.disabled,
													});
												});
										})
										.catch((error) => {
											console.log(error, 'name');
										});

									firebase
										.auth()
										.signOut()
										.then(() => {
											console.log('Signed Out');
										})
										.catch((error) => {
											console.log(error, 'auth');
											signupComponent.setState({
												disabled: !signupComponent.state.disabled,
											});
										});
								})
								.catch((error) => {
									console.log(error, 'overall');
									signupComponent.renderSnackbar(error.message, 'error');
									signupComponent.setState({
										disabled: !signupComponent.state.disabled,
									});
								});
						} else {
							signupComponent.renderSnackbar('Please enter a valid mobile number!', 'error');
						}
					} else {
						signupComponent.renderSnackbar('Enter IITK Email Address!', 'info');
					}
				} else {
					signupComponent.renderSnackbar('Please fill all the fields!', 'error');
				}
			} else {
				signupComponent.renderSnackbar('Passwords do not match!', 'error');
			}
		} else {
			signupComponent.renderSnackbar('Please fill all the fields!', 'error');
		}
	};

	addDataToFirestore = () => {
		const signupComponent = this;

		const { name, email, role, post, mobileNumber, roll } = signupComponent.state;
		email.toLowerCase();

		firebase
			.firestore()
			.collection('users')
			.add({
				name: name,
				email: email,
				role: role,
				post: post,
				mobileNumber: mobileNumber,
				roll: roll,
				isAdmin: false,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
			})
			.then((docRef) => {
				signupComponent.renderSnackbar('User created successfully, to verify your account check your inbox!', 'success');

				signupComponent.setState({
					name: '',
					email: '',
					password: '',
					confirmPassword: '',
					role: '',
					post: '',
					mobileNumber: '',
					roll: '',
					disabled: !signupComponent.state.disabled,
				});
			})
			.catch((error) => {
				console.error('Error adding document: ', error, 'firestore');
				signupComponent.setState({
					disabled: !signupComponent.state.disabled,
				});
			});
	};

	componentDidMount() {
		if (localStorage.getItem('token') === null || localStorage.getItem('token') === '') {
		} else {
			var decoded = jwt_decode(localStorage.getItem('token'));
			var dateNow = new Date();

			if (decoded.exp < Math.floor(dateNow.getTime() / 1000)) {
				localStorage.removeItem('token');
				window.open('/login', '_self');
			} else {
				window.open('/', '_self');
			}
		}
	}

	render() {
		const { classes } = this.props;

		const postsAvailaible = [
			['For the campaigner of Prathu Agrawal(Senator BT/BS Y24)','For the campaigner of Prathu Agrawal(Senator BT/BS Y24)'],
			['For the campaigner of Akanksha(Senator BT/BS Y24)','For the campaigner of Akanksha(Senator BT/BS Y24)'],
			['For the campaigner of Ranjan Ojha(Senator BT/BS Y24)','For the campaigner of Ranjan Ojha(Senator BT/BS Y24)'],
			['For the campaigner of Ritvija Katare(Senator BT/BS Y24)','For the campaigner of Ritvija Katare(Senator BT/BS Y24)'],
			['For the campaigner of Krish Agarwal(Senator BT/BS Y24)','For the campaigner of Krish Agarwal(Senator BT/BS Y24)'],
			['For the campaigner of Yashvardhan Singh Chauhan(Senator BT/BS Y24)','For the campaigner of Yashvardhan Singh Chauhan(Senator BT/BS Y24)'],
			['For the campaigner of Sarthak Bharti(Senator BT/BS Y24)','For the campaigner of Sarthak Bharti(Senator BT/BS Y24)'],
			['For the campaigner of Vatsal Jain(Senator BT/BS Y24)','For the campaigner of Vatsal Jain(Senator BT/BS Y24)'],
			['For the campaigner of Mehul Talwar(Senator BT/BS Y24)','For the campaigner of Mehul Talwar(Senator BT/BS Y24)'],
			['For the campaigner of Shivansh pandey(Senator BT/BS Y24)','For the campaigner of Shivansh pandey(Senator BT/BS Y24)'],
			['For the campaigner of Palthya Suman(Senator BT/BS Y24)','For the campaigner of Palthya Suman(Senator BT/BS Y24)'],
			['For the campaigner of Himanshu Gupta(Senator BT/BS Y24)','For the campaigner of Himanshu Gupta(Senator BT/BS Y24)'],
			['For the campaigner of Spandan Kapil Patil(Senator BT/BS Y24)','For the campaigner of Spandan Kapil Patil(Senator BT/BS Y24)'],
			['For the campaigner of Arjun Singla(Senator BT/BS Y24)','For the campaigner of Arjun Singla(Senator BT/BS Y24)'],
			['For the campaigner of Pratyush Sandhwar(Senator BT/BS Y24)','For the campaigner of Pratyush Sandhwar(Senator BT/BS Y24)'],
			['For the campaigner of Namya Mayur Jarag(Senator BT/BS Y24)','For the campaigner of Namya Mayur Jarag(Senator BT/BS Y24)'],
			['For the campaigner of Daksh Gupta(Senator BT/BS Y24)','For the campaigner of Daksh Gupta(Senator BT/BS Y24)'],
			['For the campaigner of Parth Aggarwal(Senator BT/BS Y24)','For the campaigner of Parth Aggarwal(Senator BT/BS Y24)'],
			['For the campaigner of Atharv dubey(Senator BT/BS Y24)','For the campaigner of Atharv dubey(Senator BT/BS Y24)'],
			['For the campaigner of Swatantra Kumar jagrwal(Senator BT/BS Y24)','For the campaigner of Swatantra Kumar jagrwal(Senator BT/BS Y24)'],
			['For the campaigner of Aryaman Oberoi(Senator BT/BS Y24)','For the campaigner of Aryaman Oberoi(Senator BT/BS Y24)'],
			['For the campaigner of Jyotirmoy Boro(Senator BT/BS Y24)','For the campaigner of Jyotirmoy Boro(Senator BT/BS Y24)'],
			['For the campaigner of Keerthisiri Naik(Senator BT/BS Y24)','For the campaigner of Keerthisiri Naik(Senator BT/BS Y24)'],
			['For the campaigner of Shikhar Gupta(Senator BT/BS Y24)','For the campaigner of Shikhar Gupta(Senator BT/BS Y24)'],
			['For the campaigner of Varun Tokas(Senator BT/BS Y21)','For the campaigner of Varun Tokas(Senator BT/BS Y21)'],
			['For the campaigner of Deven Anil Gangwani(Senator BT/BS Y21)','For the campaigner of Deven Anil Gangwani(Senator BT/BS Y21)'],
			['For the campaigner of Supritum Karmakar(Senator BT/BS Y21)','For the campaigner of Supritum Karmakar(Senator BT/BS Y21)'],
			['For the campaigner of Aditi Gupta(Senator MSc Y24 (including MSc-PhD Y24))','For the campaigner of Aditi Gupta(Senator MSc Y24 (including MSc-PhD Y24))'],
			
			['For the campaigner of Mohammad Zahid khan(Senator PhD Y24(including MSc-PhD Y22))','For the campaigner of Mohammad Zahid khan(Senator PhD Y24(including MSc-PhD Y22))'],
			['For the campaigner of Ashwani Kumar(Senator PhD Y24(including MSc-PhD Y22))', 'For the campaigner of Ashwani Kumar(Senator PhD Y24(including MSc-PhD Y22))'],
			['For the campaigner of Adarsh Tiwari (Senator PhD Y24(including MSc-PhD Y22))','For the campaigner of Adarsh Tiwari (Senator PhD Y24(including MSc-PhD Y22))'],
			['For the campaigner of Puspendu Adhikari(Senator PhD Y24(including MSc-PhD Y22))','For the campaigner of Puspendu Adhikari(Senator PhD Y24(including MSc-PhD Y22))'],
			['For the campaigner of Mayank Bhardwaj(Senator PhD Y24(including MSc-PhD Y22))','For the campaigner of Mayank Bhardwaj(Senator PhD Y24(including MSc-PhD Y22))'],
			['For the campaigner of Manav Mani Tripathi(Senator M.Tech Y24)','For the campaigner of Manav Mani Tripathi(Senator M.Tech Y24)'],
			['For the campaigner of Lavanya Pareek(Senator MSc Y24(including MSc-PhD Y24))','For the campaigner of Lavanya Pareek(Senator MSc Y24(inculding MSc-PhD Y24))'],
			['For the campaigner of Pankaj Singh(Senator M.Tech (2 year) Y24)','For the campaigner of Pankaj Singh(Senator M.Tech (2 year) Y24)'],
			['For the campaigner of Shivam Trivedi(Senator PhD Y21 and earlier batches)','For the campaigner of Shivam Trivedi(Senator PhD Y21 and earlier batches)'],
			['For the campaigner of Viswa Prasad S V(Senator M.Tech (2 year) Y24)','For the campaigner of Viswa Prasad S V(Senator M.Tech (2 year) Y24)'],
			['For the campaigner of Dhruv Kanoj(Senator MBA and MDes and other two-year programmes Y24)','For the campaigner of Dhruv Kanoj(Senator MBA and MDes and other two-year programmes Y24)'],
			['For the campaigner of Dipanshu Manawat(Senator M.Tech (2 year) Y24)','For the campaigner of Dipanshu Manawat(Senator M.Tech (2 year) Y24)']
		];

		const candidateDetails = [
			{
				label: 'Full Name',
				name: 'name',
				type: 'text',
				required: true,
				value: this.state.name,
				placeholder: 'Enter your full name',
			},
			{
				label: 'Email-ID',
				name: 'email',
				type: 'email',
				required: true,
				value: this.state.email,
				placeholder: 'Enter your IITK email address',
			},
			{
				label: 'Password',
				name: 'password',
				type: 'password',
				required: true,
				value: this.state.password,
				placeholder: 'Enter your password',
			},
			{
				label: 'Confirm Password',
				name: 'confirmPassword',
				type: 'password',
				required: true,
				value: this.state.confirmPassword,
				placeholder: 'Enter your password once again',
			},
			{
				label: 'Mobile Number',
				name: 'mobileNumber',
				type: 'number',
				required: true,
				value: this.state.mobileNumber,
				placeholder: 'Enter your mobile number',
			},
			{
				label: 'Roll Number',
				name: 'roll',
				type: 'number',
				required: true,
				value: this.state.roll,
				placeholder: 'Enter your IITK Roll Number',
			},
		];

		return (
			<>
				<CssBaseline />
				<div className={classes.appbar}></div>

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				<Container maxWidth='sm' className={classes.container}>
					<Typography variant='h4' align='center' className={classes.mainHeader} gutterBottom>
						SignUp
					</Typography>

					{candidateDetails.map((item, index) => (
						<TextField
							name={item.name}
							value={item.value}
							label={item.label}
							type={item.type}
							required={item.required}
							placeholder={item.placeholder}
							onChange={this.onChange}
							variant='outlined'
							className={classes.inputField}
						/>
					))}

					<FormControl variant='outlined' required={true} className={classes.inputField}>
						<InputLabel htmlFor='outlined-age-native-simple'>Select your Role</InputLabel>
						<Select
							native
							value={this.state.role}
							onChange={this.onChange}
							label='Select your Role'
							disabled={false}
							inputProps={{
								name: 'role',
								id: 'outlined-age-native-simple',
							}}
						>
							<option aria-label='None' value='' />
							<option value='Campaigner'>Campaigner</option>
							{/* <option value='Proposer'>Proposer</option>
							<option value='Seconder'>Seconder</option> */}
							{/*Only for campaigner registration  */}
							{/* <option value='Campaigner'>Campaigner</option> */} 
						</Select>
					</FormControl>

					<FormControl variant='outlined' required={true} className={classes.inputField}>
						<InputLabel htmlFor='outlined-age-native-simple'>For the Post of (*Only if you are candidate)</InputLabel>
						<Select
							native
							value={this.state.post}
							onChange={this.onChange}
							label='For the Post of'
							inputProps={{
								name: 'post',
								id: 'outlined-age-native-simple',
							}}
						>
							<option aria-label='None' value='' />
							{postsAvailaible.map((post, index) => (
								<option key={index} value={post[0]}>
									{post[1]}
								</option>
							))}
						</Select>
					</FormControl>

					<div className={classes.wrapper}>
						<Button variant='contained' color='primary' className={classes.buttonClassname} fullWidth disabled={this.state.disabled} onClick={this.signup}>
							SignUp
						</Button>
						{this.state.disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
					</div>
				</Container>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Signup);
