import firebase from 'firebase';

const config = {
	apiKey: 'AIzaSyCo9NHn498ScyXU-X5oIzdakwJODD_yaKA',
	authDomain: 'ec-nomination-portal.firebaseapp.com',
	projectId: 'ec-nomination-portal',
	storageBucket: 'ec-nomination-portal.appspot.com',
	messagingSenderId: '38543374393',
	appId: '1:38543374393:web:dd149c7de6be6ddaa175e8',
};

firebase.initializeApp(config);
firebase.analytics();

export { firebase };
