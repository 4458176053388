import React from 'react';
import { CssBaseline, Typography, Container, withStyles, BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import logo_black from './../assets/images/logo_black.png';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CreateIcon from '@mui/icons-material/Create';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import jwt_decode from 'jwt-decode';
import { firebase } from './../Firebase';

const styles = (theme) => ({
	container: {
		marginTop: theme.spacing(21),
		marginBottom: theme.spacing(8),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(20) },
	},
	logo: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		width: theme.spacing(40),
	},
	mainHeader: {
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: { fontSize: theme.spacing(5) },
	},
	bottomNavigation: {
		backgroundColor: '#f7f6f6',
		fontColor: '#000',
		fontWeight: 'bold',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(8),
	},
	actionButton: {
		fontWeight: 'bold',
		transform: 'scale(1.5)',
	},
});

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLogin: false,
			isAdmin: false,
			role:"",
		};
	}

	componentDidMount() {
		const homeComponent = this;

		this.setState({
			isLogin: localStorage.getItem('token') === null || localStorage.getItem('token') === '' ? false : true,
		});

		if (localStorage.getItem('token') === null || localStorage.getItem('token') === '') {
		} else {
			var decoded = jwt_decode(localStorage.getItem('token'));
			var dateNow = new Date();

			if (decoded.exp < Math.floor(dateNow.getTime() / 1000)) {
				localStorage.removeItem('token');
				this.setState({
					isLogin: false,
				});
				window.open('/login', '_self');
			} else {
				firebase
					.firestore()
					.collection('users')
					.where('email', '==', decoded.email)
					.onSnapshot((querySnapshot) => {
						const profileId = [];

						querySnapshot.forEach(function (doc) {
							profileId.push(doc.data());
						});

						homeComponent.setState({
							isAdmin: profileId[0].isAdmin === true,
        					role: profileId[0].role,
      						}, () => {
        					console.log('Role:', this.state.role);
      					});
					});
			}
		}
	}

	render() {
		const { classes } = this.props;

		return (
			<>
				<CssBaseline />
				<Container maxWidth='lg' className={classes.container}>
					<Typography variant='h2' align='center' className={classes.mainHeader} gutterBottom>
						Election Commission, IIT Kanpur
					</Typography>

					<img src={logo_black} className={classes.logo} alt='logo' />

					{!this.state.isLogin ? (
						<BottomNavigation
							showLabels
							onChange={(event, value) => {
								if (!this.state.isLogin) {
									if (value === 0) {
										window.open('/login', '_self');
									} else {
										window.open('/signup', '_self');
									}
								}
							}}
							className={classes.bottomNavigation}
						>
							<BottomNavigationAction className={classes.actionButton} label='Login' icon={<LoginIcon />} />
							<BottomNavigationAction className={classes.actionButton} label='SignUp' icon={<PersonAddAlt1Icon />} />
						</BottomNavigation>
					) : (
						<BottomNavigation
							showLabels
							onChange={(event, value) => {
								if (this.state.isLogin) {
									if (value === 0) {
										window.open('/nomination', '_self');
									} else if (value === 1) {
										window.open('/myteam', '_self');
									} else if (value === 2) {
										window.open('/profile', '_self');
									} else if (value === 3) {
										window.open('/admin', '_self');
									}
								}
							}}
							className={classes.bottomNavigation}
						>
							{/* {this.state.role === 'Candidate' && (
								<>
								<BottomNavigationAction className={classes.actionButton} label='Nomination' icon={<CreateIcon />} />
									<BottomNavigationAction showLabels className={classes.actionButton} label='MyTeam' icon={<GroupsIcon />} />
								</>
							)} */}
							<BottomNavigationAction className={classes.actionButton} label='Nomination' icon={<CreateIcon />} />
							<BottomNavigationAction className={classes.actionButton} label='MyTeam' icon={<GroupsIcon />} />
							<BottomNavigationAction className={classes.actionButton} label='Profile' icon={<AccountCircleIcon />} />
						
						</BottomNavigation>
					)}

					{this.state.isAdmin && this.state.isLogin ? (
						<BottomNavigation
							showLabels
							onChange={(event, value) => {
								window.open('/admin', '_self');
							}}
							className={classes.bottomNavigation}
						>
							
							<BottomNavigationAction className={classes.actionButton} label='Admin' icon={<AdminPanelSettingsIcon />} />
						</BottomNavigation>
					) : null}
				</Container>
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Home);
