import React from 'react';
import { CircularProgress, Avatar, Grid, Link, CssBaseline, Typography, Button, TextField, Container, withStyles } from '@material-ui/core';
import { firebase } from './../Firebase';
import Snackbar from './../Components/Snackbar';
import Loader from './../Components/Loader';
import NotFound from './404';

const styles = (theme) => ({
	appbar: theme.mixins.toolbar,
	logo: {
		textAlign: 'center',
		width: '50%',
	},
	inputField: {
		width: '100%',
		marginBottom: theme.spacing(1),
	},
	loginButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	root: {
		position: 'relative',
		textAlign: 'center',
		marginBottom: theme.spacing(8),
	},
	profileImage: {
		width: '200px',
		height: '200px',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	profileImageHolder: {
		textAlign: 'center',
		marginBottom: theme.spacing(4),
	},
	container: {
		marginTop: theme.spacing(7),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(15) },
	},
	container_al: {
		marginTop: theme.spacing(12),
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: { marginTop: theme.spacing(15) },
		marginBottom: theme.spacing(6),
		minHeight: (window.innerHeight * 43.7) / 100,
	},
	editButton: {
		float: 'left',
	},
	updateButton: {
		float: 'right',
	},
	BackToPortal: {
		float: 'center',
		marginTop: theme.spacing(4),
	},
});

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			post: '',
			role: '',
			id: '',
			email: '',
			mobileNumber: 0,
			roll: 0,
			address: '',
			isLogin: false,
			isLoading: true,
			image: '',
			imageURL: '',
			progress_image: 0,
			snackbarMessage: '',
			snackbarSeverity: '',
			snackbarOpen: false,
			disabled: false,
		};
	}

	onChange = (e) =>
		this.setState({
			[e.target.name]: e.target.value,
		});

	renderSnackbar = (snackbarMessage, snackbarSeverity) => {
		this.setState({
			snackbarMessage: snackbarMessage,
			snackbarSeverity: snackbarSeverity,
			snackbarOpen: true,
		});
	};

	componentDidMount() {
		const profileComponent = this;

		firebase.auth().onAuthStateChanged(function (user) {
			if (user) {
				profileComponent.setState({ email: user.email, isLogin: true }, function () {
					firebase
						.firestore()
						.collection('users')
						.where('email', '==', profileComponent.state.email)
						.onSnapshot((querySnapshot) => {
							const profileInfo = [];
							const profileId = [];

							querySnapshot.forEach(function (doc) {
								profileInfo.push(doc.data());
								profileId.push(doc.id);
							});

							profileComponent.setState({
								name: profileInfo[0].name,
								email: profileInfo[0].email,
								post: profileInfo[0].post,
								role: profileInfo[0].role,
								mobileNumber: profileInfo[0].mobileNumber,
								roll: profileInfo[0].roll,
								address: profileInfo[0].address,
								imageURL: profileInfo[0].imageURL,
								isLoading: false,
								id: profileId[0],
							});
						});
				});
			} else {
				profileComponent.setState({ isLoading: false, isLogin: false });
			}
		});
	}

	onImageChange = async (e) => {
		if (e.target.files[0]) {
			const file = e.target.files[0];
			this.setState({
				image: file.name,
				disabled: true,
			});

			const timeStamp = Date.now();
			const profileComponent = this;

			var uploadTask = firebase
				.storage()
				.ref()
				.child('profile/' + timeStamp + file.name)
				.put(file);

			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED,
				function (snapshot) {
					var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					this.setState({ progress_image: progress });

					if (progress === 100) {
						var url;

						setTimeout(() => {
							firebase
								.storage()
								.ref()
								.child('profile/' + timeStamp + file.name)
								.getDownloadURL()
								.then(function (downloadURL) {
									console.log('File available at', downloadURL);
									url = downloadURL;
									profileComponent.setState({
										imageURL: url,
									});

									profileComponent.renderSnackbar('Image Uploaded Successfully!', 'success');
									profileComponent.onProfileUpdate();

									setTimeout(function () {
										profileComponent.setState({
											progress_image: 0,
											disabled: false,
										});
									}, 500);
								});
						}, 1300);
					}
				}.bind(this),
				function (error) {
					profileComponent.renderSnackbar('Error in updating image!', 'error');

					switch (error.code) {
						case 'storage/unauthorized':
							break;

						case 'storage/canceled':
							break;
						case 'storage/unknown':
							break;
						default:
							break;
					}
				}
			);
		} else {
			this.setState(() => ({ image: 'image' }));
		}
	};

	onProfileUpdate = (e) => {
		const profileComponent = this;

		const { mobileNumber, address, roll, id, imageURL } = this.state;

		if (mobileNumber.length !== 10 || parseFloat(mobileNumber) < 0 || mobileNumber.includes('.')) {
			profileComponent.renderSnackbar('Please enter a valid mobile number!', 'error');

			return;
		}
		if ((roll.length !== 6 && roll.length !== 8 && roll.length !==9) || parseFloat(roll) < 0 || roll.includes('.')) {
			profileComponent.renderSnackbar('Please enter a valid roll number!', 'error');

			return;
		}
		if (e !== undefined) e.preventDefault();
		this.setState({
			disabled: !this.state.disabled,
		});
		firebase
			.firestore()
			.collection('users')
			.doc(id)
			.update({
				roll: roll === undefined ? '' : roll,
				address: address === undefined ? '' : address,
				mobileNumber: mobileNumber === undefined ? '' : mobileNumber,
				imageURL: imageURL === undefined ? '' : imageURL,
			})
			.then(function () {
				profileComponent.renderSnackbar('Profile Updated Successfully!', 'success');
			})
			.catch(function (error) {
				console.error('Error updating document: ', error);
				profileComponent.renderSnackbar('Error in updating profile!', 'error');
			});
	};

	onEditButtonClick = (e) => {
		e.preventDefault();

		this.setState({
			disabled: !this.state.disabled,
		});
	};

	render() {
		const { classes } = this.props;

		const textFields = [
			['Name', 'name', this.state.name, true, 'text'],
			['Email', 'email', this.state.email, true, 'email'],
			['Role', 'role', this.state.role, true, 'text'],
			['Phone Number', 'mobileNumber', this.state.mobileNumber, !this.state.disabled, 'number'],
			['Address', 'address', this.state.address, !this.state.disabled, 'text'],
			['Roll Number', 'roll', this.state.roll, !this.state.disabled, 'number'],
			['Post', 'post', this.state.post, true, 'text'],
		];

		return (
			<>
				<CssBaseline />
				<div className={classes.appbar}></div>

				{this.state.snackbarOpen ? (
					<Snackbar open={this.state.snackbarOpen} close={() => this.setState({ snackbarOpen: false })} message={this.state.snackbarMessage} severity={this.state.snackbarSeverity} />
				) : null}

				{this.state.isLoading ? (
					<Loader />
				) : (
					<>
						{this.state.isLogin ? (
							<Container className={classes.container} maxWidth='md'>
								<Typography variant='h2' align='center' gutterBottom>
									Your Profile
								</Typography>

								<Grid container className={classes.root} direction='row' justify='space-evenly' alignItems='flex-start' spacing={2}>
									<Grid item xs={12} sm={5}>
										<div className={classes.profileImageHolder} style={{ position: 'relative' }}>
											<span style={{ position: 'absolute' }}>
												<Avatar className={classes.profileImage} src={this.state.imageURL} />
											</span>
											<CircularProgress variant='determinate' value={this.state.progress_image} size={200} thickness={1.8} />
										</div>

										<input accept='image/*' className={classes.input} id='contained-button-file' multiple type='file' onChange={this.onImageChange} style={{ display: 'none' }} />
										<label htmlFor='contained-button-file'>
											<Button variant='contained' disabled={!this.state.disabled} disableElevation color='secondary' size='large' component='span'>
												Update Photo
											</Button>
										</label>
									</Grid>
									<Grid item xs={12} sm={7}>
										{textFields.map((handle, index) => (
											<TextField
												key={index}
												variant='outlined'
												className={classes.inputField}
												type={handle[4]}
												label={handle[0]}
												name={handle[1]}
												value={handle[2]}
												onChange={this.onChange}
												disabled={handle[3]}
											/>
										))}

										<Button
											variant='contained'
											className={classes.editButton}
											color='secondary'
											size='large'
											disabled={this.state.disabled}
											disableElevation
											onClick={this.onEditButtonClick}
										>
											Edit Profile
										</Button>

										<Button
											variant='contained'
											className={classes.updateButton}
											color='secondary'
											size='large'
											disabled={!this.state.disabled}
											disableElevation
											onClick={this.onProfileUpdate}
										>
											Update Profile
										</Button>
									</Grid>
									<Grid item xs={12} sm={7}>
										<Link href='/' underline='none'>
											<Button variant='contained' className={classes.BackToPortal} color='primary' size='large'>
												Back to Portal
											</Button>
										</Link>
									</Grid>
								</Grid>
							</Container>
						) : (
							<NotFound />
						)}
					</>
				)}
			</>
		);
	}
}

export default withStyles(styles, { withTheme: true })(Profile);
