import React from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import theme from './Views/Theme';
import { responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import Profile from './Pages/Profile';
import MyTeam from './Pages/MyTeam';
import Nomination from './Pages/Nomination';
import Admin from './Pages/Admin';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import NotFound from './Pages/404';
import ForgotPassword from './Pages/ForgotPassword';

const themes = responsiveFontSizes(theme);

function App() {
	var hist = createBrowserHistory();
	return (
		<ThemeProvider theme={themes}>
			<Router history={hist} style={{ backgroundColor: '#f7f6f6', flexGrow: 1 }}>
				<Navbar />
				<Switch>
					<Route exact path='/' component={Home} />
					<Route exact path='/login' component={Login} />
					<Route exact path='/signup' component={Signup} />
					<Route exact path='/profile' component={Profile} />
					<Route exact path='/myteam' component={MyTeam} />
					<Route exact path='/nomination' component={Nomination} />
					<Route exact path='/forgot-password' component={ForgotPassword} />
					<Route exact path='/admin' component={Admin} />
					<Route path='' component={NotFound} />
				</Switch>
				<Footer />
			</Router>
		</ThemeProvider>
	);
}

export default App;
